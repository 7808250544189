<template>
  <div class="callouts">
    <div class="items">
      <CalloutSpiritualityAndHealthMagazine
        v-if="spiritualityAndHealthMagazine"
        class="item sh-magazine aspect-[3/4]"
        :="spiritualityAndHealthMagazine"
        :status="status"
        :locale="locale"
      />
      <CalloutUnity
        v-if="latestArticle"
        class="item latest aspect-square"
        :="latestArticle"
        :status="status"
        :locale="locale"
      />
      <CalloutPromotion class="item promo aspect-square" :locale="locale" />
      <CalloutDailyWordMagazine
        v-if="dailyWordMagazine"
        class="item daily-word aspect-[3/4]"
        :="dailyWordMagazine"
        :status="status"
        :locale="locale"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { parseURL, stringifyParsedURL } from 'ufo'

const {
  public: {
    unity: { baseUrl: unityBaseUrl },
  },
} = useRuntimeConfig()

const useUnityOrgPath = (_path?: string) => {
  const { host, protocol } = parseURL(unityBaseUrl)

  return stringifyParsedURL({
    ...parseURL(_path),
    host,
    protocol,
  })
}

const { locale } = useI18n()

const { data, status } = await useFetch('/api/data/content/latest', {
  query: { lang: locale },
})

const spiritualityAndHealthMagazine = computed(() => {
  const val = unref(data)?.spiritualityAndHealthMagazine
  if (!val) return
  return {
    ...val,
    path: useUnityOrgPath(val.path),
  }
})
const latestArticle = computed(() => {
  const val = unref(data)?.latestArticle
  if (!val) return
  return {
    ...val,
    path: useUnityOrgPath(val.path),
  }
})
const dailyWordMagazine = computed(() => {
  const val = unref(data)?.dailyWordMagazine
  if (!val) return
  return {
    ...val,
    path: useUnityOrgPath(val.path),
  }
})
</script>

<style lang="postcss" scoped>
.callouts {
  container: callouts / inline-size;
}

.flip {
  transform-style: preserve-3d;
}

.items {
  @apply space-y-4;
}

.item {
  @apply w-full min-w-[160px] break-inside-avoid break-words;

  hyphens: auto;
}

@container callouts (min-width: 320px) {
  .items {
    @apply columns-2;
  }
}

@container callouts (min-width: 200px) {
  .item {
    @apply mx-auto max-w-[240px];
  }
}
</style>
